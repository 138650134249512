  /* Import css files */
@import './variables.css';

/* Global styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7b1fa223;
  /* background-color: var(--foreground-color); */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* Content classes */

.content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max-width);
  width: 100%;
}

.text-shadow {
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.55);
}
.main-wrapper {
  padding-top: var(--header-height);
  min-height: 100vh;
}
.rounded {
  border-radius: 2px;
}
.rtl-rotate {
  transform: rotate(180deg);
}
/* Main classes */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiButton-root,
.MuiTab-root {
  text-transform: inherit !important;
}
.pointer{
  cursor: pointer !important;
}

.MuiButton-root.MuiButton-outlined {
  border-width: 2px !important;
}

#event-messages-content {
  position: fixed;
  top: var(--header-height);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
  z-index: 2500;
  left: 50%;
  transform: translateX(-50%);
}

#event-messages-content .message-wrapper {
  position: relative;
  max-width: 100vw;
  width: 850px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
  text-align: center;
}

#messages-content {
  position: fixed;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
  z-index: 2500;
}

html[dir='ltr'] #messages-content {
  left: 0;
}

html[dir='rtl'] #messages-content {
  right: 0;
}

#messages-content .message-wrapper {
  position: relative;
  width: 300px;
  color: #fff;
  overflow: hidden;
}

#messages-content .message-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #ffffff99;
  z-index: 1;
}

html[dir='ltr'] #messages-content .message-wrapper::after {
  right: 0;
}

html[dir='rtl'] #messages-content .message-wrapper::after {
  left: 0;
}

.translate-up {
  transition: all 0.5s ease-out;
}

.translate-up:hover {
  transform: translateY(-7px) !important;
}

.my-svg {
  filter: invert(16%) sepia(29%) saturate(4729%) hue-rotate(275deg) brightness(68%) contrast(96%);
}

.gray-svg {
  filter: invert(37%) sepia(10%) saturate(7%) hue-rotate(12deg) brightness(92%) contrast(84%);
}

@media print {
  #application-footer,
  #application-header,
  .dont-display-in-print {
    display: none;
  }
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 70px;
  background-color: #fff;
  padding: 15px;
}
.MuiTooltip-tooltip {
  background-color: #833089 !important;
  font-size: 12px !important;
  /* box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.25), inset -4px 4px 5px rgba(0, 0, 0, 0.25); */
}
.MuiTooltip-arrow {
  color: #833089 !important;
}

.reactour__helper {
  font-size: 14px;
  padding: 24px 25px !important;
  color: white !important;
  background: rgba(115, 19, 154, 0.85) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.reactour__helper button {
  color: #fff !important;
}
.reactour__helper div[data-tour-elem='controls'] {
  margin-top: 10px !important;
}

.reactour__helper button:disabled {
  color: #ffffff9d !important;
}
.reactour__close {
  top: 15px !important;
  right: 15px !important;
  color: #ffffff9d !important;
}
.reactour__close:hover {
  color: #ffffff !important;
}

.highlight {
  background-color: #fdff32;
  border-radius: 2px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
