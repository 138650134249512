:root {
  --content-max-width: 1534px;
  --header-height: 77px;
  --swiper-navigation-size: 15px !important;
  --swiper-navigation-color: #777 !important;
  --swiper-theme-color: #777 !important;
}

@media (max-width: 1200px) {
  :root {
    --content-max-width: 1050px;
  }
}

@media (max-width: 992px) {
  :root {
    --header-height: 61px;
  }
}
